import React from "react";
import "./Homepage.css";
import Gallery from "./Gallery.js";
import PhotoCarousel from "./PhotoCarousel.js";
import Navbar from "./Navbar.js";

export default function Homepage() {
    return (
        <div className="homepage" id="home">
            <Navbar />
			{/* Embed hero.html using an iframe */}
                <iframe
                    src={`${process.env.PUBLIC_URL}/hero.html`}
                    title="Hero Section"
                    width="100%"
                    height="600px" /* Adjust height as needed */
                    style={{ border: "none" }}
                ></iframe>
            <header className="hero">
                <h1>The new Web3 Hub in Vienna</h1>
                <p>
                    based in the DC Tower 3, 'District Working'.
                </p>
                <div className="carousel">
                    <PhotoCarousel />
                </div>
				
				 
				
                <button className="discover-btn">
                    <a href="#location">Find us</a>
                </button>
            </header>
            <section className="about">
                <h2 className="about" id="about">
                    #about
                </h2>

                <p>
                    <a
                                href="https://dltaustria.com"
                                target="_blank"
                                rel="noreferrer"
                            >DLT Austria</a> the non-profit Association is celebrating the opening of the new Web3 Hub
                    in Vienna. The hub is located in the DC Tower 3, 'District
                    Working', Donau-City-Straße 3, 1220 Vienna. The nearest
                    subway station is U1 Kaisermühlen. The event will take place
                    in both the outdoor and indoor areas. With this new hub in
                    District Living, Vienna is taking another important step in
                    the development of the blockchain and Web3 scene,
                    establishing itself as a central European hub for
                    distributed ledger technologies and their diverse
                    applications in Web3.
                </p>
                <p>
                    <h3>Vienna – an Emerging Hub for Blockchain and Web3</h3>
                    In the Austrian capital, more than 100 players are already
                    active—ranging from startups and established companies to
                    innovation centers, research institutions, and investors—who
                    are deeply engaged with the diverse application areas of
                    blockchain and Web3. Vienna impresses with a dynamic,
                    innovative, and versatile ecosystem that has long surpassed
                    the traditional use cases of cryptocurrencies.
                </p>

                <h3 className="location" id="location">
                    District Living – A Location with a Bright Future
                </h3>

                <p>
                    The Web3 Hub is located in DC Tower 3 'District Working', one
                    of Vienna's leading technology centers, surrounded by
                    renowned companies such as PwC, EY, Oracle, Iteratec, and
                    many others. In close proximity are numerous event venues
                    like TechGate, Ares Tower, DC1 Tower, Saturn Tower,
                    Andromeda Tower, and IZD Tower. Should a large Web3
                    conference take place one day, the Austrian Center is just a
                    few steps away and would be the ideal venue for thousands of
                    guests from all over the world. The central location offers
                    excellent transport connections and a variety of additional
                    amenities, creating optimal conditions for both national and
                    international players. The location is easily accessible:
                    right next to the A22 highway with ample parking spaces in
                    the underground garage and close to the "Vienna
                    International Center" (U1) subway station, well-known for
                    the UNO City and the Austria Center. Vienna North train
                    station is only three stops away, and the Vienna Airport Bus
                    brings travelers directly from the airport to the doorstep
                    and back. The surrounding area offers numerous restaurants,
                    bars, fitness centers, a wellness area, a panoramic terrace,
                    hotels, and a student dormitory for international students.
                    In the future, well-known crypto companies like Solana,
                    Polygon, Polkadot, Coinbase, Ethereum, and Cosmos will
                    regularly host events and hackathons there.
                    <br />
                    <div>
                        <img
                            className="location-image"
                            src="./images/google-maps-location.png"
                            alt="Google Maps Location"
                        />
                        <button className="GoogleMaps">
                            <a
                                href="https://www.google.com/maps/place/District+Living/@48.2309335,16.4077777,16z/data=!4m9!3m8!1s0x476d0713b5af60b9:0xbfc92fae1dc1c43b!5m2!4m1!1i2!8m2!3d48.2315312!4d16.4148831!16s%2Fg%2F11p12r2n2p?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Go to Google Maps
                            </a>
                        </button>
                    </div>
                </p>
                <h3>DLT Austria – Vision and Mission</h3>
                <p>
                    The vision of DLT Austria is to create an international
                    ecosystem for all stakeholders in the Web3 sector, thereby
                    strengthening Austria as a location in a sustainable way.
                    With the Web3 Hub, DLT Austria aims to provide a platform
                    where companies, startups, innovators, and investors can
                    connect and develop together. DLT Austria has already
                    established its office there, and the first resident crypto
                    startup, AQ Forensic, specializing in crypto forensics and
                    analytics, is also on-site. Discussions with additional
                    companies are already underway.{" "}
                </p>
                <p>
                    DLT Austria seeks to establish partnerships with various
                    international hubs, including Crypto Valley in Zug,
                    Switzerland, the Web3.Hub in Berlin, and many others. The
                    goal is to foster exchanges between hubs and expand support
                    in the education sector. To achieve this, international
                    speakers will be invited to share valuable insights and
                    strengthen knowledge transfer.
                </p>
            </section>
            <section className="gallery" id="gallery">
                <h2>#gallery</h2>
                <Gallery />
            </section>

            <footer className="footer d-flex" id="legal">
                <div className="socials">
                    <h3>Socials</h3>
                    <ul>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/dltaustria/posts/?feedView=all"
                                target="_blank"
                                rel="noreferrer"
                            >
                                LinkedIn
                            </a>
                        </li>
                       
                        <li><a
                                href=" https://x.com/DLTAustria"
                                target="_blank"
                                rel="noreferrer"
                            >
                                X
                            </a></li>
                    </ul>
                </div>
                <div className="contact ">
                    <h3>DLT Austria</h3>
                    <div>District Living</div>
					 <div>Office 13</div>
					 <div>Donau-City-Straße 3</div>
					 <div>1220</div>
					 <div>Vienna</div>
					 <div>Austria</div>
                   
                    <div className="email">
                        <span>Email: </span>office@dltaustria.com
                    </div>
                </div>

                <div className="legal">
                    <h3>Legal</h3>
                    <ul>
                        <li><a
                                href="https://dltaustria.com/#imprint"
                                target="_blank"
                                rel="noreferrer"
                            >Impressum </a></li>
                       <li><a
                                href="https://dltaustria.com/data-protection"
                                target="_blank"
                                rel="noreferrer"
                            >Datenschutz </a></li>
                    </ul>
                </div>
            </footer>
            <div className="credits">
                created by{" "}
                <strong>
                    <a
                        href="https://github.com/NCH1210"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Nina
                    </a>
                </strong>
            </div>
            <br />
            <span className="back-to-top"></span>
        </div>
    );
}
