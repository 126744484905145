import React from "react";
import "./Gallery.css"; // Create a CSS file for styling

const Gallery = () => {
    // Array holding the image paths
    const images = [
        "/images/image7.jpg",
        "/images/image6.jpg",
        "/images/image9.jpg",
        "/images/image13.jpg",
        "/images/image8.jpg",
        "/images/image3.jpg",
        "/images/image2.jpg",
        "./images/image1.jpg",
        "/images/image5.jpg",
        "/images/image4.jpg",
        "/images/image14.jpg",
        "/images/image11.jpg",
		
		 "/images/image15.jpg",
		  "/images/image16.jpg",
		   "/images/image17.jpg",
		    "/images/image18.jpg",
			 "/images/image19.jpg",
			  "/images/image20.jpg",
			   "/images/image21.jpg",
			    "/images/image22.jpg",
				 "/images/image23.jpg",
				  "/images/image24.jpg",
				   "/images/image25.jpg",
				    "/images/image26.jpg",
					 "/images/image27.jpg",
					  "/images/image28.jpg",
					   "/images/image29.jpg",
					    "/images/image30.jpg",
						"/images/image31.jpg",
						"/images/image32.jpg",
						"/images/image33.jpg",
						"/images/image34.jpg",
						"/images/image35.jpg",
						"/images/image36.jpg",
						"/images/image37.jpg",
						"/images/image38.jpg",
						"/images/image39.jpg",
						"/images/image40.jpg",
						"/images/image41.jpg",
						"/images/image42.jpg",
						"/images/image43.jpg",
						"/images/image44.jpg",
						

        // Add more images here
    ];

    return (
        <div className="gallery-container">
            {images.map((image, index) => (
                <div className="gallery-item" key={index}>
                    <img src={image} alt={`Gallery ${index}`} />
                </div>
            ))}
        </div>
    );
};

export default Gallery;
