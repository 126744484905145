import "./App.css";
import Homepage from "./Homepage.js";
import GradientCanvas from "./GradientCanvas.js";

function App() {
    return (
        <div className="App gradient-background">
            <GradientCanvas />
            <Homepage />
        </div>
    );
}


export default App;

