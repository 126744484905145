import React, { useState, useEffect } from "react";
import "./Navbar.css";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the mobile menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Close the mobile menu when a link is clicked
    const closeMenu = () => {
        setIsOpen(false);
    };

    // Close mobile menu on window resize if screen width > 768px
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsOpen(false); // Close the mobile menu
            }
        };

        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures it runs only once

    return (
        <div className="nav-container">
            <nav className="navbar">
                {/* Desktop Navbar */}
                <div className="navbar-desktop">
                    <ul className="navbar-links">
                        <li>
                            <a href="#about">About</a>
                        </li>
                        <li>
                            <a href="#location">Location</a>
                        </li>
                        <div className="navbar-logo">
                            <a href="https://w3hub.wien/">
                                <img src="./logo.png" alt="Logo" />
                            </a>
                        </div>
                        <li>
                            <a href="#gallery">Gallery</a>
                        </li>
						 <li>
                            <a href="./assets/directions_web3_hub_vienna.pdf"  target="_blank"
                                rel="noreferrer">Directions</a>
                        </li>
                        <li>
                            <a href="#legal">Legal</a>
                        </li>
                    </ul>
                </div>

                {/* Hamburger Menu Icon for Mobile */}
                <div className="hamburger-menu-icon" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                {/* Mobile Navbar */}
                <ul className={`navbar-links-mobile ${isOpen ? "open" : ""}`}>
                    {/* Add logo to the top of the mobile menu */}
                    <div className="mobile-navbar-logo">
                        <img src="./logo.png" alt="Logo" />
                    </div>
                    <li>
                        <a href="#about" onClick={closeMenu}>
                            About
                        </a>
                    </li>
                    <li>
                        <a href="#location" onClick={closeMenu}>
                            Location
                        </a>
                    </li>
                    <li>
                        <a href="#gallery" onClick={closeMenu}>
                            Gallery
                        </a>
                    </li>
                    <li>
                        <a href="#legal" onClick={closeMenu}>
                            Legal
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;
