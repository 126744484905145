import { useEffect } from "react";

const GradientCanvas = () => {
    useEffect(() => {
        const canvas = document.getElementById("gradientCanvas");
        const ctx = canvas.getContext("2d");

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };
        resizeCanvas();

        window.addEventListener("resize", resizeCanvas);

        let points = [
            {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                dx: Math.random() * 4 - 2,
                dy: Math.random() * 4 - 2,
            },
            {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                dx: Math.random() * 4 - 2,
                dy: Math.random() * 4 - 2,
            },
            {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                dx: Math.random() * 4 - 2,
                dy: Math.random() * 4 - 2,
            },
        ];

        function drawGradient() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            points.forEach((point, index) => {
                const gradient = ctx.createRadialGradient(
                    point.x,
                    point.y,
                    0,
                    point.x,
                    point.y,
                    canvas.width * 1.5
                );

                gradient.addColorStop(0, "rgba(86, 204, 242, 0.3)");
                gradient.addColorStop(0.2, "rgba(224, 98, 153, 0.3)");
                gradient.addColorStop(0.4, "rgba(39, 181, 196, 0.3)");
                gradient.addColorStop(0.6, "rgba(238, 174, 202, 0.3)");
                gradient.addColorStop(0.8, "rgba(148, 187, 233, 0.3)");
                gradient.addColorStop(1, "rgba(108, 55, 123, 0.3)");

                ctx.fillStyle = gradient;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
            });
        }

        function updatePoints() {
            points.forEach((point) => {
                point.x += point.dx;
                point.y += point.dy;

                if (point.x < 0 || point.x > canvas.width) point.dx *= -1;
                if (point.y < 0 || point.y > canvas.height) point.dy *= -1;
            });
        }

        function animate() {
            drawGradient();
            updatePoints();
            requestAnimationFrame(animate);
        }

        animate();

        return () => {
            window.removeEventListener("resize", resizeCanvas);
        };
    }, []);

    return <canvas id="gradientCanvas" />;
};

export default GradientCanvas;
