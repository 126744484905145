import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PhotoCarousel.css";

const PhotoCarousel = () => {
    const settings = {
        infinite: true, // Endlose Schleife der Bilder
        speed: 500, // Übergangsgeschwindigkeit (in Millisekunden)
        slidesToShow: 1, // Anzahl der gleichzeitig angezeigten Folien
        slidesToScroll: 1, // Anzahl der Folien, die pro Scrollvorgang bewegt werden
        autoplay: true, // Automatisches Abspielen der Bilder
        autoplaySpeed: 3000, // Geschwindigkeit des automatischen Abspielens
    };

    return (
        <div className="carousel">
            <Slider {...settings}>
                <div>
                    <img src="./images/image7.jpg" alt="Bild 1" />
                </div>
                <div>
                    <img src="./images/image14.jpg" alt="Bild 2" />
                </div>
                <div>
                    <img src="./images/image9.jpg" alt="Bild 3" />
                </div>
                <div>
                    <img src="./images/image6.jpg" alt="Bild 2" />
                </div>
                <div>
                    <img src="./images/image8.jpg" alt="Bild 2" />
                </div>
                <div>
                    <img src="./images/image10.jpg" alt="Bild 2" />
                </div>

                {/* Weitere Bilder hinzufügen */}
            </Slider>
        </div>
    );
};

export default PhotoCarousel;
